import Vuex from 'vuex'
import { store } from 'quasar/wrappers'

import asset from './asset'
import auth from './auth'
import common from './common'
import content from './content'
import document from 'hc-core/stores/document'
import inbox from './inbox'
import order from './order'
import rating from './rating'
import search from './search'
import transaction from './transaction'
import user from './user'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      asset,
      auth,
      common,
      content,
      document,
      inbox,
      order,
      rating,
      search,
      transaction,
      user
    }
  })

  return Store
})
